import axios from 'axios';
const rcoBackendPrefix = '/portal/Bankszamla/api';
export default {
	getWebsalesId() {
		return axios.get(rcoBackendPrefix + '/websales/cookie');
	},
	sendPackageData(packageData) {
		return axios.post(rcoBackendPrefix + '/websales/uj-szamlacsomag', packageData);
	}
};
