import { provide, ref } from 'vue';
import Vue from 'vue';
const DSZK_COOKIE_NAME = 'userHasDszk';
const DIREKT_COOKIE_NAME = 'userHasDirekt';
const userHasDszk = ref(false);
const userHasDirekt = ref(false);
const getCookies = () => {
	userHasDirekt.value = Vue.cookie.get(DIREKT_COOKIE_NAME) == 'true';
	const dszkValue = parseInt(Vue.cookie.get(DSZK_COOKIE_NAME), 10);
	if (!isNaN(dszkValue)) {
		userHasDszk.value = !!dszkValue;
	}
};
getCookies();
const getCookieOptions = () => {
	const cookieOptions = {
		path: '/',
		SameSite: 'strict'
	};
	const isOtp = window.location.hostname.endsWith('otpbank.hu');
	const domain = isOtp && '.otpbank.hu';
	if (domain) {
		cookieOptions.domain = '.otpbank.hu';
	}
	const isHttps = window.location.protocol == 'https:';
	if (isHttps) {
		cookieOptions.secure = true;
	}
	return cookieOptions;
};
const setDirektCookie = () => {
	const cookieOptions = getCookieOptions();
	cookieOptions.expires = '2Y';
	Vue.cookie.set(DIREKT_COOKIE_NAME, true, cookieOptions);
	userHasDirekt.value = true;
};
const deleteDirektCookie = () => {
	Vue.cookie.delete(DIREKT_COOKIE_NAME, getCookieOptions());
	userHasDirekt.value = false;
};
const provideDirektEbValues = () => {
	provide('userHasDszk', userHasDszk);
	provide('userHasDirekt', userHasDirekt);
};
export const useDirektIbCookieHandler = () => {
	return {
		provideDirektEbValues,
		setDirektCookie,
		deleteDirektCookie
	};
};
