export const DISPLAY_DATE_FORMAT = {
	hu: 'YYYY.MM.DD',
	en: 'MM/DD/YYYY'
};
export const DISPLAY_TIME_FORMAT = {
	hu: 'HH:mm',
	en: 'hh:mm A'
};
export const BACKEND_DATE_FORMAT = 'YYYY-MM-DD';
export const NAME_REGEXP = "^[\\p{L}\\s\\.\\-\\']*$";
export const FULLNAME_REGEXP = "^[\\p{L}\\.\\-\\']+\\s[\\p{L}\\s\\.\\-\\']+$";
export const ADDRESS_CARD_REGEXP = '^\\d{6}[a-zA-Z]{2}$';
export const LANDLINE_PHONE_REGEXPS = ['^1\\s\\d{3}\\s\\d{4}$', '^[02-9]\\d\\s\\d{3}\\s\\d{3,4}$'];
export const MOBILE_PHONE_REGEXP = '^(70|20|30|31|50)\\s\\d{3}\\s\\d{4}$';
export const ALL_PHONE_REGEXP = ['^1\\s\\d{3}\\s\\d{4}$', '^[02-9]\\d\\s\\d{3}\\s\\d{3,4}$', '^(70|20|30|31|50)\\s\\d{3}\\s\\d{4}$'];
export const IDENTITY_REGEXPS = {
	'personal-id': '^\\d{6}[a-zA-Z]{2}$',
	'drivers-license': '^[a-zA-Z]{2}\\d{6}$',
	passport: '[a-zA-Z]{2}\\d{7}$'
};
export const ZIP_CODE_REGEXP = '^\\d{4}$';
export const PENSIONER_NUMBER_REGEXP = '^\\d{3}-\\d{5}-\\d{1}$';
export const YEAR_REGEXP = '^\\d{4}$';
export const TAX_NUMBER_REGEXP = '^[1-79]\\d{7}-\\d{1}-\\d{2}$';
export const TAX_IDENTIFIER_REGEXP = '^\\d{10}$';
export const BANK_ACCOUNT_SIXTEEN_NUMBERS_REGEXP = '^\\d{8}-\\d{8}$';
export const BANK_ACCOUNT_SIXTEEN_NUMBERS_REGEXP_WITH_HYPHEN = '^\\d{8}-\\d{8}-$';
export const BANK_ACCOUNT_SIXTEEN_NUMBERS_REGEXP_WITH_RESIDENTIAL_PREFIX = '^11773\\d{3}-\\d{8}$';
export const BANK_ACCOUNT_THIRTEEN_NUMBERS_REGEXP = '^\\d{5}-\\d{8}$';
export const DEVIZA_BANK_ACCOUNT_NUMBER_PATTERN = '^75\\d{3}-\\d{5}88\\d$';
export const BANK_ACCOUNT_TWENTY_FOUR_NUMBERS_REGEXP = '^\\d{8}-\\d{8}-\\d{8}$';
export const MIN_FIVE_CHARACTERS = '^[0-9a-zA-Z]{5,}$';
export const OTP_BANK_ACCOUNT_NUMBER_PREFIX = '117';
export const HUNGARIAN_PRONE_NUMBER_PREFIX = '+36';
export const COOKIE_GDPR_LEVEL = 'gdpr_level';
export const COOKIE_GDPR_LEVEL_VERSION = 'gdpr_level_version';
export const GDPR_LEVEL_VERSION = 2;
export const GDPR_LEVELS = {
	LOW: 1,
	MEDIUM: 2,
	HIGH: 3
};
export const IOS_REGEXP = /ipad|iphone/i;
export const FOREIGN_ZIP_CODE_REGEXP = /^[\da-zA-Z]{1,10}$/g;
export const FOREIGN_PHONE_REGEX = /^\d{1,3}-\d{1,10}$/;
export const LAST_FOUR_DIGITS_REGEXP = /^\d{4}$/;
export const GOOGLE_MAPS_CONSTANTS = {
	MAP_POI: {
		url: '/static/portal/assets/img/application/time-reservation/icon/map-poi.png'
	},
	MAP_POI_HIGHLIGHTED: {
		url: '/static/portal/assets/img/application/time-reservation/icon/map-poi-highlighted.png'
	},
	MAP_USER_LOCATION: {
		url: '/static/portal/assets/img/application/time-reservation/icon/map-user-location.png'
	}
};
export const ERROR_FIELDS = {
	CAPTCHA: 'captcha'
};
export const COOKIE_PORTAL_ID_NAME = 'portalId';
export const COOKIE_PORTAL_ID_VERSION_NAME = 'portalIdVersion';
export const REGISTRATION_NUMBER_REGEXP = '^\\d{2}-\\d{2}-\\d{7}$';
export const COMPANY_REGISTRATION_NUMBER_REGEXP = '^\\d{2}-\\d{2}-\\d{6}$';
export const MOBILE_PROVIDERS = {
	20: 'YETTEL',
	30: 'TELEKOM',
	31: 'VODAFONE',
	50: 'DIGI',
	70: 'VODAFONE'
};
export const PASSWORD_CHARACTERS_REGEXP = '^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d)+$';
export const INTRNETBANK_PASSWORD_REGEXP = '^[a-zA-Z0-9]*$';
export const PASSWORD_REGEXP = '^(?=.*[A-Z].*)(?=.*[a-z].*)(?=.*\\d.*).{8,}$';
export const ERROR_CODES = {
	REQUIRED: 'REQUIRED',
	WRONG_FORMAT: 'WRONG_FORMAT',
	FORMAT_ERROR: 'FORMAT_ERROR',
	WRONG_EMAIL: 'WRONG_EMAIL',
	TOO_LONG: 'TOO_LONG',
	TOO_SHORT: 'TOO_SHORT',
	DATE_NOT_BEFORE_DATE_FIELD: 'DATE_NOT_BEFORE_DATE_FIELD',
	DATE_NOT_BETWEEN: 'DATE_NOT_BETWEEN'
};
export const DISPLAY_DATE_FORMAT_FNS = {
	hu: 'yyyy.MM.dd.',
	en: 'MM/DD/yyyy'
};
export const ANDROID_PHONE_REGEX = /\bAndroid(?:.+)Mobile\b/i;
export const IPHONE_REGEX = /iPhone/i;
export const EMAIL_REGEXP = '^([a-zA-Z0-9\\-\\.\\_]+)(\\@)([a-zA-Z0-9\\-\\.]+)(\\.)([a-zA-Z]{2,4})$';
