import axios from 'axios';
export default {
	getWidgetRate() {
		return axios.get('/apps/exchangerate/api/widgetdata');
	},
	getCurrencies() {
		return axios.get('/apps/exchangerate/api/exchangerate/currencies');
	},
	exchangeCalculation(model) {
		return axios.post('/apps/exchangerate/api/exchangerate/exchange', model);
	},
	getExchangeVersions(date) {
		return axios.get(`/apps/exchangerate/api/exchangerate/otp/${date}`);
	},
	getMNBVersions(date) {
		return axios.get(`/apps/exchangerate/api/exchangerate/mnb/${date}`);
	},
	getForeignIndividualVersions(date) {
		return axios.get(`/apps/exchangerate/api/exchangerate/foreign/individual/${date}`);
	},
	getForeignStandardVersions(date) {
		return axios.get(`/apps/exchangerate/api/exchangerate/foreign/standard/${date}`);
	},
	getGraphData(model) {
		return axios.get(`/apps/exchangerate/api/exchangerate/chart/${model.currency}/${model.from}/${model.to}`);
	},
	getMessages(keys, subjectDate) {
		const params = { keys };
		subjectDate && (params['subject-date'] = subjectDate);
		return axios.get('/apps/exchangerate/api/messages/messages', { params });
	},
	getTypes(model) {
		return axios.get(`/apps/exchangerate/api/exchangerate/rateTypes/${model.currency}`, { params: { dateFrom: model.from, dateTo: model.to } });
	},
	getOfflineVersions(date) {
		return axios.get(`/apps/exchangerate/api/exchangerate/offline/${date}`);
	},
	getCardData(date) {
		return axios.get(`/apps/exchangerate/api/exchangerate/card/${date}`);
	},
	getForeignInterest(interestedType, date, isEbkm) {
		let params = {};
		if (isEbkm) {
			params.params = {
				ebkm: isEbkm
			};
		}
		return axios.get(`/apps/exchangerate/api/foreignInterest${interestedType}/${date}`, params);
	},
	getEcbDifference() {
		return axios.get('/apps/exchangerate/api/exchangerate/difference');
	},
	getConversionData(date) {
		return axios.get(`/apps/exchangerate/api/exchangerate/ex-card/${date}`);
	}
};
